import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";

import footerStyles from "./footer.module.css";
import logo from "../../../static/assets/facts-first-logo-white.svg";

class Footer extends Component {
  render() {
    return (
      <div className={footerStyles.footerWrapper + " " + ["global-footer"]}>        
        <Row>
          <Col md={2}>
            <img src={logo} alt="Facts Are First Logo" />
          </Col>
          <Col className="text-center">
            <div className={footerStyles.bottomRow}>&copy;Facts First 2020</div>
            <div className={footerStyles.linkRow1}>
              <a href="/articles">Latest Articles</a> |{" "}
              <a href="/compare-presidents">Compare Presidents</a> |{" "}
              <a href="/presidents">Presidential Performance</a> |{" "}
              <a href="/metrics">Metrics</a> |{" "}
              <a href="/learning-center">Learning Center</a>
            </div>
            <div className={footerStyles.linkRow2}>
              <a href="/about">About Us</a> |{" "}              
              <a href="/contact">Contact Us</a> |{" "}
              <a href="/terms">Terms Of Use</a> |{" "}
              <a href="/privacy">Privacy Policy</a>
            </div>
          </Col>
          <Col md={2}>
            <a href="https://www.facebook.com/FactsAreFirst/">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://twitter.com/FactsRFirst">
              <FontAwesomeIcon icon={faTwitter} />
            </a>            
          </Col>
        </Row>        
      </div>
    );
  }
}

export default Footer;
