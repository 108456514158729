import React, { useState, useRef } from "react"
import { navigate } from "@reach/router";
import {Form, FormControl } from "react-bootstrap";


const SearchForm = ({ initialQuery = "" }) => {

  const [query, setQuery] = useState(initialQuery)
  
  const inputEl = useRef(null);

  // On input change use the current value of the input field (e.target.value)
  // to update the state's query value
  const handleChange = e => {
    setQuery(e.target.value);    
  }
  
  const handleSubmit = e => {  
    e.preventDefault();
  
    // `inputEl.current` points to the mounted search input element
    const q = inputEl.current.value
    navigate(`/search?q=${q}`)
  }


  return (
    <Form role="search" inline style={{margin: 0}} onSubmit={handleSubmit.bind(this)}>
      <FormControl 
        type="text" 
        placeholder="Search" 
        className="mr-sm-2" 
        onChange={handleChange}
        ref={inputEl}
        value={query}
        id="search-input"
      />  
      { console.log(query) }
    </Form>
  )
}
export default SearchForm