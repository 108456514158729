import React, { Component } from "react"
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons'
import SearchForm from "../common/SearchForm"; 

import headerStyles from "./header.module.css"
import logo from "../../../static/assets/facts-first-logo-orange.svg"

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      navIconClass: '',
      navMenu: ''
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    if (!this.state.menuOpen) {
      this.setState({
        menuOpen: true,
        navIconClass: 'open',
        navMenu: 'active'
      });
    } else {
      this.setState({
        menuOpen: false,
        navIconClass: '',
        navMenu: ''
      });
    }
  }

  render() {
    return (
      <div className={headerStyles.headerWrapper}>
        <Navbar bg="dark" variant="dark" expand="lg">
          <Navbar.Brand href="/">
            <img src={logo} alt="Facts are First Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/articles">Articles</Nav.Link>
              <NavDropdown title="Compare Presidents" id="basic-nav-dropdown">
                <NavDropdown.Item href="/compare-presidents">
                  All Presidents
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/comparison/joe-biden/donald-trump">
                  Biden vs. Trump
                </NavDropdown.Item>
                <NavDropdown.Item href="/comparison/joe-biden/barack-obama">
                  Biden vs. Obama
                </NavDropdown.Item>                
                <NavDropdown.Item href="/comparison/donald-trump/barack-obama">
                  Trump vs. Obama
                </NavDropdown.Item>
                <NavDropdown.Item href="/comparison/barack-obama/bill-clinton">
                  Obama vs. Clinton
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Presidential Performance"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/presidents">
                  All Presidents
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/presidents/joe-biden">
                  Joe Biden
                </NavDropdown.Item>                
                <NavDropdown.Item href="/presidents/donald-trump">
                  Donald Trump
                </NavDropdown.Item>
                <NavDropdown.Item href="/presidents/barack-obama">
                  Barack Obama
                </NavDropdown.Item>
                <NavDropdown.Item href="/presidents/george-w-bush">
                  George W Bush
                </NavDropdown.Item>
                <NavDropdown.Item href="/presidents/bill-clinton">
                  Bill Clinton
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Metrics" id="basic-nav-dropdown">
                <NavDropdown.Item href="/metrics">Metrics</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/metrics/stock-market-performance">
                  Stock Market
                </NavDropdown.Item>
                <NavDropdown.Item href="/metrics/gdp-growth">
                  GDP
                </NavDropdown.Item>
                <NavDropdown.Item href="/metrics/jobs-growth">
                  Jobs Created
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/learning-center">Learning Center</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
            </Nav>

            <SearchForm />
            <Nav.Item>
              <a href="https://www.facebook.com/FactsAreFirst/">
              <FontAwesomeIcon
                icon={faFacebookF}
                className={headerStyles.socialHeaderButton}
              />
              </a>
              <a href="https://twitter.com/FactsRFirst">
              <FontAwesomeIcon
                icon={faTwitter}
                className={headerStyles.socialHeaderButton}
              />
              </a>
            </Nav.Item>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }

}

export default Header; 

