import React, { Component } from "react"

import Header from "../components/header/header" 
import Footer from "../components/footer/footer"
import Helmet from "react-helmet"

import "../../static/styles/reset.css";
import "../../static/styles/global.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../static/styles/bootstrap-override.css";
import favicon from "../../static/favicon.ico"; 

class DefaultLayout extends Component {
  render() {
    return (
        <div>
        <Header/>
        <Helmet>
          <link rel="icon" href={favicon} />
        </Helmet>
        <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
          {this.props.children}
        <Footer />
        </div>
    )
  }
}

export default DefaultLayout
